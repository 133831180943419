// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // TODO: Specify URI for your development environment
  appUrl: 'https://eldx-zlogs-xxx-feat-main.dev.zonarsystems.net',
  prefix: 'dev',
  authEnv: 'development',
  name: 'dev',
  region: 'NA',
  complianceApplicationId: '7b848b7a-621c-4690-8f18-a59bf17b6edc',
  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  auth: {
    clientID: '84osJUq7FJvf1IpPA7L5yCL8N6eh3DXZ',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: '0c96e82d-da2d-4858-9169-b7729516a960',
    defaultZonarRole: '725509ee-4f44-4ec0-a24a-d8593d67a7d3',
    useRefreshTokens: true
  },
  urls: {
    udlApi: `/udlapi`,
    baseApi: `https://api-dev.zonarsystems.net`,
    externalApi: 'https://api-dev.zonarsystems.net/core/v1',
    complianceUrl: `https://cd-zlogs-xxx-sidenavftrbranch.dev.zonarsystems.net`, //Todo: change it to compliance.dev
    driverLogsApi: '/driver-logs-api',
    legacyEntityApi: '/legacy-entity-api',
    hosReportApi: `/hos-report-api`,
    GTC4: `https://gtc4dev-cloud.zonarsystems.net/`
  },
  helpDocs: {
    UDL: `https://support.zonarsystems.net/hc/en-us/articles/4414469986701-Unidentified-Driver-Logs-UDLs-`,
    UDL_AutoAssignYM: `https://support.zonarsystems.net/hc/en-us/articles/16961143878925-Auto-Assigning-UDLs-in-Yards-or-Zones`,
    ELDx_helpDocs: `https://support.zonarsystems.net/hc/en-us/articles/24556147665293-Driver-Logs-for-ELD-New`,
    NDR: `https://support.zonarsystems.net/hc/en-us/articles/17999726420365`
  },
  production: false,
  hereApiKey: undefined,
  hereV3ApiKey: undefined,
  hereMaps: {
    hereApiKey: undefined,
    hereV3ApiKey: undefined,
    useV3: true,
    // hereUrl: '/map-data/{s}/maptile/2.1' for v2 apis
    hereUrl: '/map-data-v3'
  },
  datadog: {
    applicationId: 'b3fd3a90-06ed-48ca-9023-9cba94d00a02',
    clientToken: 'pub8a652e107235c257e7f966cc72f1784e',
    site: 'us5.datadoghq.com',
    service: 'eldx',
  },
  devCycle: {
    clientId: 'dvc_client_ccad0b8d_bb59_42be_939c_836c5a854789_8eb19ec'
  },
  excludeAccounts: ['foo'],
  swiftAccRegexCheck: `([Dd][Ee][Vv][Ss][Ww][Ii])([0-9]{3})`,
  swiftAccToChangeTo: `devswi0000`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
